import React, { useState, useEffect } from 'react';
import PanelLayout from '../../components/PanelLayout';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { Client } from './interfaces';
import moment from 'moment';
import CreateUpdate from './CreateUpdate';
import { toast } from 'react-toastify';

const Clients = () => {
    const [clients, setClients] = useState<Array<Client>>([]);
    const [loading, setLoading] = useState(true);

    const getClients = (callback?: () => any) => {
        const api = `${API}/api/clients`;
        Axios.get(api, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            let clients: Array<Client> = [];
            if (res.status === 200){
                clients = res.data;
            }
            setClients(clients);
            setLoading(false);
            if (callback) callback();
        })
    }

    const deleteClient = (id: number) => {
        const userConfirms = window.confirm("¿Realmente desea eliminar el recurso seleccionado?\nEsta acción no se puede deshacer.");
        if (userConfirms){
            const api = `${API}/api/clients/${id}`;
            Axios.delete(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                if (res.status === 200){
                    toast.info("Cliente eliminado correctamente");
                    getClients();
                }
            })
        }
    }

    useEffect(() => {
        getClients();
    }, []);

    if (loading){
        return (
            <PanelLayout>
                <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Cargando...</span>
                    </div>
                </div>
            </PanelLayout>
        )
    }
    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Clientes</h2>
                    </div>
                    {clients.map(client => (
                    <div className="col-12 col-md-6 col-lg-4" key={client.id}>
                        <div className="card">
                            <img src={`${API}${client.logoPath}`} className="card-img-top" alt={client.name} />
                            <div className="card-body">
                                <h5 className="card-title">{client.name}</h5>
                                <p className="card-text">
                                    Cliente creado el {moment(client.created_at).format("DD/MM/YYYY hh:mm a")}. Última vez actualidado el {moment(client.updated_at).utc().format("DD/MM/YYYY HH:mm a")}.
                                </p>
                                <CreateUpdate refresh={getClients} client={client} />
                                <button onClick={() => deleteClient(client.id || 0)} className="btn btn-danger ml-2">Eliminar</button>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            <CreateUpdate refresh={getClients} />
        </PanelLayout>
    );
};

export default Clients;