import React, { FunctionComponent } from 'react';
import styles from './WaveLayout.module.scss';
import moment from 'moment';

interface WaveLayoutProps {

}

const WaveLayout: FunctionComponent<WaveLayoutProps> = ({ children }) => {
    return (
        <section className={styles.container}>
            {children}
            <div style={{position: "relative", zIndex: 1100, bottom: 50, textAlign: "center"}}>
                Copyright {moment().format("YYYY")} - Desarrollado por <a href="https://nodeaxan.com/" rel="noopener noreferrer" target="_blank">NodeAxan</a>
            </div>
            <div className={`${styles.wave} ${styles.wave1}`}></div>
            <div className={`${styles.wave} ${styles.wave2}`}></div>
            <div className={`${styles.wave} ${styles.wave3}`}></div>
            <div className={`${styles.wave} ${styles.wave4}`}></div>
        </section>
    );
};

export default WaveLayout;