import { mdiAccount, mdiHome, mdiAccountHeartOutline, mdiPackageVariantClosed,
        mdiWrench, mdiCog, mdiAppsBox, mdiComment } from '@mdi/js'

export const links = [
    {
        url: "/home",
        name: "Inicio",
        divider: false,
        icon: mdiHome
    },
    {
        url: "/clients",
        name: "Clientes",
        divider: false,
        icon: mdiAccountHeartOutline
    },
    {
        url: "/products",
        name: "Productos",
        divider: false,
        icon: mdiPackageVariantClosed
    },
    {
        url: "/projects",
        name: "Proyectos",
        divider: false,
        icon: mdiWrench
    },
    {
        url: "/categories",
        name: "Categorías",
        divider: false,
        icon: mdiAppsBox
    },
    {
        url: "/comments",
        name: "Comentarios",
        divider: false,
        icon: mdiComment
    },
    {
        url: "/users",
        name: "Usuarios",
        divider: true,
        icon: mdiAccount
    },
    {
        url: "/settings",
        name: "Ajustes",
        divider: false,
        icon: mdiCog
    }
]