import React from 'react';
import PanelLayout from '../../components/PanelLayout';
import Card from '../../components/Card';
import { mdiWrench, mdiPackageVariantClosed, mdiTankerTruck } from '@mdi/js';
import { useHistory } from 'react-router-dom';

const Categories = () => {
    const history = useHistory();

    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Categorías</h2>
                    </div>
                    <div className="col-md-4">
                        <Card 
                        title="Proyectos"
                        icon={mdiWrench}
                        buttons={[{text: "Ir", onClick: () => {history.push('/categories/projects')}}]}
                        >
                            Gestione las categorías de sus proyectos.
                        </Card>
                    </div>
                    <div className="col-md-4">
                        <Card 
                        title="Productos"
                        icon={mdiPackageVariantClosed}
                        buttons={[{text: "Ir", onClick: () => {history.push('/categories/products')}}]}
                        >
                            Gestione las categorías de sus productos.
                        </Card>
                    </div>
                    <div className="col-md-4">
                        <Card 
                        title="Servicios"
                        icon={mdiTankerTruck}
                        buttons={[{text: "Ir", onClick: () => {history.push('/categories/services')}}]}
                        >
                            Gestione las categorías de sus productos.
                        </Card>
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
};

export default Categories;