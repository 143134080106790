import React, { useState, FormEvent, FunctionComponent } from 'react';
import WaveLayout from './../components/WaveLayout';
import { API } from './../utils/endpoints';
import Axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
// import {useAudio} from 'react-use';

interface LoginProps extends RouteComponentProps {
}

const Login: FunctionComponent<LoginProps> = ({ history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    // const [audio, state, controls, ref] = useAudio({
    //     src: require('./../sounds/login.mp3'),
    //     autoPlay: false,
    // });

    const login = (e: FormEvent) => {
        setLoggingIn(true);
        e.preventDefault();
        const api = `${API}/api/login`;
        const data = {email, password}
        Axios.post(api, data).then(res => {
            if (res.status === 201){
                // controls.play();
                sessionStorage.setItem('jwt', res.data.token);
                history.push('/home');
                // setTimeout(() => {
                // }, 2000)
            }
            setLoggingIn(false);
        }).catch(err => {
            setError(true);
            setLoggingIn(false);
        })
    }

    return (
        <div>
            {/* {audio} */}
            <WaveLayout>
                <div className="container">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                        <div className="card shadow">
                            <div className="card-body">
                                <h5 className="card-title">Iniciar sesión</h5>
                                <p className="card-text">
                                    Inicie sesión para administrar el sitio web de CORSUR.
                                </p>
                                <hr/>
                                <form onSubmit={login}>
                                    <div className="form-group">
                                        <label htmlFor="email">Correo electrónico</label>
                                        <input onChange={e => setEmail(e.target.value)} required name="email" className="form-control" type="email" placeholder="arya.stark@hotmail.com"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Contraseña</label>
                                        <input onChange={e => setPassword(e.target.value)} required name="password" className="form-control" type="password" placeholder="Su contraseña"/>
                                    </div>
                                    {error ?
                                    <span style={{display: "block"}} className="text-danger">Usario y/o contraseña no válido(s).</span>
                                    : null}
                                    <button className="btn btn-secondary btn-block" disabled={loggingIn}>{loggingIn ? "Iniciando sesión..." : "Iniciar sesión"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </WaveLayout>
        </div>
    );
};

export default Login;