import React, { FunctionComponent, useState } from 'react';
import { Project } from './interfaces';
import Modal from 'react-modal';
import { API } from '../../utils/endpoints';
import moment from 'moment';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Image } from '../Products/interfaces';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 200,
        maxWidth: "80%",
        minHeight: "70%"
    }
}

Modal.setAppElement('#root');

interface ImagesProps {
    project: Project,
    refresh: () => any,
}

const Images: FunctionComponent<ImagesProps> = ({ project, refresh }) => {
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState(project.images || []);
    const [uploading, setUploading] = useState(false);

    const onClose = () => {
        setIsOpen(false);
        refresh();
    }

    const getImages = () => {
        setLoading(true);
        const api = `${API}/api/images`;
        Axios.get(api, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            let images: Array<Image> = [];
            if (res.status === 200) {
                images = res.data;
                images = images.filter(image => (typeof image.project_id === "string" ? parseInt(image.project_id) : image.project_id) === project.id);
            }
            setImages(images);
            setLoading(false);
        })
    }

    const deleteImage = (image: Image) => {
        const userConfirms = window.confirm("¿Realmente desea eliminar el recurso seleccionado?\nEsta acción no se puede deshacer.");
        if (userConfirms) {
            const api = `${API}/api/images/${image.id}`;
            Axios.delete(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                if (res.status === 200) {
                    toast.info("Imagen eliminada correctamente");
                    getImages();
                }
            })
        }
    }

    const saveImage = () => {
        setUploading(true);
        const api = `${API}/api/images`;
        const imageFormData = new FormData();
        const fileElement = document.getElementById("file") as HTMLInputElement;
        if (fileElement.files) {
            imageFormData.append("file", fileElement.files[0]);
            imageFormData.append("project_id", `${project.id}`);

            Axios.post(api, imageFormData, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                    'content-type': 'multipart/form-data',
                }
            }).then(res => {
                if (res.status === 200) {
                    toast.success("Imagen añadida exitosamente.");
                }
                setUploading(false);
                getImages();
            }).catch(err => {
                setUploading(false);
                toast.error(`Algo ha salido mal: ${err}`);
            })
        }

    }

    return (
        <div style={{ display: "inline-block" }}>
            <button className="btn btn-primary m-1 ml-0" onClick={() => setIsOpen(true)}>Imágenes</button>
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={onClose}>
                <div className="row">
                    <div className="col-12">
                        <h2>Imagenes de {project.name}</h2>
                    </div>
                    <div className="col-12" style={{overflow: "auto", maxHeight: 300}}>
                        <div className="row">
                            {loading ? 
                            <div className="col-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </div>
                                </div>
                            </div>
                            :
                            images.map(image => (
                                <div className="col-md-2" key={image.id}>
                                    <div className="card w-100 h-100">
                                        <img src={`${API}${image.path}`} className="card-img-top" alt={project.name} />
                                        <div className="card-body">
                                            <p className="card-text">
                                                Imagen subida el {moment(image.created_at).format("DD/MM/YYYY hh:mm a")}.
                                            </p>
                                            <button onClick={() => deleteImage(image)} className="btn btn-danger">Eliminar</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <input accept="image/png, image/jpeg" disabled={uploading} onChange={saveImage} name="file" type="file" id="file" style={{width: 0.1, height: 0.1, overflow: "hidden", position: "absolute", zIndex: -1}} />
                        <label className={`btn btn-primary ${uploading ? "disabled" : null}`} htmlFor="file">{uploading ? "Subiendo..." : "Subir nueva imagen"}</label>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-secondary" onClick={onClose}>Cerrar</button>
                </div>
            </Modal>
        </div>
    );
};

export default Images;