import React from 'react';
import {useMedia} from 'react-use';
import PanelLayout from './../components/PanelLayout';
import Icon from '@mdi/react';
import { mdiArrowLeftBold, mdiArrowUpBold } from '@mdi/js';
import styles from './pages.module.css';

const Home = () => {
    const isPhone = useMedia('(max-width: 700px)');

    return (
        <div>
            <PanelLayout>
                <div className="container-fluid">
                    <h1>Panel de control de CORSUR Oil Services S.A. de C.V.</h1>
                    <p>Gestione la información existente en su sitio web sin necesidad de la intervención de terceros. Para comenzar, navegue en el menú de {isPhone ? "arriba" : "la izquierda"} hacia la sección específica que desee gestionar.</p>
                    {isPhone ? <Icon path={mdiArrowUpBold} size={4} className={`${styles.upArrow} ${styles.arrow}`} /> : <Icon path={mdiArrowLeftBold} size={4} className={`${styles.leftArrow} ${styles.arrow}`} />}
                </div>
            </PanelLayout>
        </div>
    );
};

export default Home;