import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

export const getBoolean = (value: string | number | boolean | undefined): boolean => {
    switch (typeof value) {
        case "string":
            return value === "1" || value === "true";
        case "number":
            return value > 0;
        case "boolean":
            return value;
        case "undefined":
            return false;
        default:
            return value;
    }
}

export const getEditorState = (html: string): EditorState => {
    // Obtenemos los datos almacenados y los metemos al editor en el formato requerido
    let editorState: EditorState;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
    } else {
        editorState = EditorState.createEmpty();
    }
    return editorState;
}

export const editorStateToHtml = (editorState: EditorState): string => {
    const draftData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return draftData;
}