import React, { useState, FunctionComponent, FormEvent } from 'react';
import Modal from 'react-modal';
import { ProjectCategory } from './interfaces';
import { API } from '../../../utils/endpoints';
import Axios from 'axios';
import { toast } from 'react-toastify';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
}

Modal.setAppElement('#root')

interface CreateCategoryProps {
    refresh: () => any
}

const CreateCategory: FunctionComponent<CreateCategoryProps> = ({ refresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [categoryData, setCategoryData] = useState<ProjectCategory>({name: ""});

    const saveCategory = (e: FormEvent) => {
        e.preventDefault();
        const api = `${API}/api/projectCategories`;
        Axios.post(api, categoryData, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Categoría añadida exitosamente.");
            }
            setIsOpen(false);
            setCategoryData({name: ""});
            refresh();
        }).catch (err => {
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div>
            <button className="btn btn-primary position-fixed" style={{bottom: 10, right: 10}} onClick={() => setIsOpen(true)}>Crear nueva categoría</button>
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={() => setIsOpen(false)}>
                <h5>Crear nueva categoría</h5>
                <form onSubmit={saveCategory} id="addUserForm">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input required value={categoryData.name} onChange={e => setCategoryData({...categoryData, name: e.target.value})} type="text" className="form-control" placeholder="Nombre"/>
                    </div>
                    <button className="btn btn-primary" type="submit">Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default CreateCategory;