import React, { useState, useEffect } from 'react';
import PanelLayout from '../../components/PanelLayout';
import { Project } from './interfaces';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { ProjectCategory } from '../Categories/ProjectCategories/interfaces';
import moment from 'moment';
import CreateUpdate from './CreateUpdate';
import { toast } from 'react-toastify';
import Images from './Images';

const Projects = () => {
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [categories, setCategories] = useState<Array<ProjectCategory>>([]);
    const [loading, setLoading] = useState(true);

    const getProjects = () => {
        setLoading(true);
        const api = `${API}/api/projects`;
        Axios.get(api, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            let projects: Array<Project> = [];
            if (res.status === 200){
                projects = res.data;
            }
            setProjects(projects);
            setLoading(false);
        })
    }

    const deleteProject = (id: number) => {
        const userConfirms = window.confirm("¿Realmente desea eliminar el recurso seleccionado?\nEsta acción no se puede deshacer.");
        if (userConfirms){
            const api = `${API}/api/projects/${id}`;
            Axios.delete(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                if (res.status === 200){
                    toast.info("Proyecto eliminado correctamente");
                    getProjects();
                }
            })
        }
    }

    useEffect(() => {
        const getCategories = () => {
            const api = `${API}/api/projectCategories`;
            Axios.get(api).then(res => {
                let categories: Array<ProjectCategory> = [];
                if (res.status === 200){
                    categories = res.data;
                }
                setCategories(categories);
            })
        }
        
        getCategories();
        getProjects();
    }, []);

    if (loading){
        return (
            <PanelLayout>
                <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Cargando...</span>
                    </div>
                </div>
            </PanelLayout>
        )
    }
    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Proyectos</h2>
                        <p>
                            Gestione sus proyectos. Recuerde que estos registros deberán tener, por lo menos, una imagen para ser visibles en su sitio web.
                        </p>
                    </div>
                    <div className="col-12 table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Categoría</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de última actualización</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map(project => (
                                    <tr key={project.id}>
                                        <td>
                                            {project.name} <br/>
                                            {!project.images?.length ? <span style={{color: "#ba000d", fontWeight: "bold"}}>Este proyecto no contiene imágenes y no se visualizará en su sitio web.</span> : null}
                                        </td>
                                        <td>
                                            <div dangerouslySetInnerHTML={{__html: project.description}}></div>
                                        </td>
                                        <td>{project.category ? project.category.name : "Error al identificar categoría"}</td>
                                        <td>{moment(project.created_at).format("DD/MM/YYYY hh:mm a")}</td>
                                        <td>{moment(project.updated_at).format("DD/MM/YYYY hh:mm a")}</td>
                                        <td>
                                            <Images project={project} refresh={getProjects} />
                                            <CreateUpdate refresh={getProjects} project={project} categories={categories} />
                                            <button onClick={() => deleteProject(project.id || 0)} className="btn btn-danger m-1 ml-0">Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <CreateUpdate refresh={getProjects} categories={categories} />
        </PanelLayout>
    );
};

export default Projects;