import React, { useState, FormEvent, FunctionComponent, useEffect } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import { API } from '../../utils/endpoints';
import { toast } from 'react-toastify';
import { Project } from './interfaces';
import { ProjectCategory } from '../Categories/ProjectCategories/interfaces';
import { getEditorState, editorStateToHtml } from '../../utils/functions';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
}

Modal.setAppElement('#root');

interface CreateUpdateProjectsProps {
    refresh: (callback?: () => any) => any,
    project?: Project,
    categories: Array<ProjectCategory>
}

const CreateUpdate: FunctionComponent<CreateUpdateProjectsProps> = ({refresh, project, categories}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [projectData, setProjectData] = useState<Project>(project || {name: "", description: "", category_id: 0});
    const [isSaving, setIsSaving] = useState(false);
    const [editor, setEditor] = useState(getEditorState(projectData.description || ""));

    useEffect(() => {
        setProjectData({...projectData, description: editorStateToHtml(editor)})
    }, [editor]);

    const save = (e: FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        let validationMessage = "";
        if (!projectData.category_id) validationMessage += "- Seleccione una categoría.\n";
        if (!projectData.name) validationMessage += "- Digite un nombre.\n"
        if (!projectData.description) validationMessage += "- Coloque una descripción.\n"
        if (validationMessage){
            toast.error(validationMessage);
            setIsSaving(false);
            return;
        }
        const api = `${API}/api/projects`;
        Axios.post(api, JSON.stringify(projectData), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Producto/servicio añadido exitosamente.");
            }
            setIsOpen(false);
            setProjectData({name: "", description: "", category_id: 0});
            refresh(() => setIsSaving(false));
        }).catch (err => {
            setIsSaving(false);
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    const update = (e: FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        let validationMessage = "";
        if (!projectData.category_id) validationMessage += "- Seleccione una categoría.\n";
        if (!projectData.name) validationMessage += "- Digite un nombre.\n"
        if (!projectData.description) validationMessage += "- Coloque una descripción.\n"
        if (validationMessage){
            toast.error(validationMessage);
            setIsSaving(false);
            return;
        }
        const api = `${API}/api/projects/${projectData.id}`;
        Axios.put(api, JSON.stringify(projectData), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Proyecto actualizado exitosamente.");
            }
            setIsOpen(false);
            refresh(() => setIsSaving(false));
        }).catch (err => {
            setIsSaving(false);
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div style={{display: projectData.id ? "inline-block" : "block"}}>
            {projectData.id ? 
            <button className="btn btn-primary" onClick={() => setIsOpen(true)}>Editar</button>
            :
            <button className="btn btn-primary position-fixed" style={{bottom: 10, right: 10}} onClick={() => setIsOpen(true)}>Crear nuevo proyecto</button>
            }
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={() => setIsOpen(false)}>
                <h5>{projectData.id ? "Editar proyecto" : "Crear nuevo proyecto"}</h5>
                <form onSubmit={projectData.id ? update : save} id="clientForm" encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input required name="name" value={projectData.name} onChange={e => setProjectData({...projectData, name: e.target.value})} type="text" className="form-control" placeholder="Nombre"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="idCategoria">Categoría</label>
                        <select required value={projectData.category_id} onChange={e => setProjectData({...projectData, category_id: parseInt(e.target.value)})} className="form-control" name="idCategoria">
                            <option value={0}>.:: Seleccione una categoría ::.</option>
                            {categories.map(category => (
                                <option value={category.id} key={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Descripción</label>
                        <div style={{border: "1px solid #dbdbdb", minHeight: 300, padding: 10, borderRadius: 5}}>
                            <Editor 
                            editorState={editor}
                            onEditorStateChange={editorState => setEditor(editorState)}
                            />
                        </div>
                    </div>
                    <button disabled={isSaving} className="btn btn-primary" type="submit">Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    )
}

export default CreateUpdate;