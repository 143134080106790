import React from 'react';
import PanelLayout from '../../components/PanelLayout';
import ChangePassword from './ChangePassword';
import FlatDesign from './FlatDesign';

const Settings = () => {
    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Ajustes</h2>
                    </div>
                    <div className="col-12">
                        <p>Configure los parámetros con los que funciona su usuario o su sitio web.</p>
                        <ChangePassword />
                        <FlatDesign />
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
};

export default Settings;