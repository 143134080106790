import React, { useState, FormEvent, FunctionComponent } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import { API } from '../../utils/endpoints';
import { toast } from 'react-toastify';
import { Client } from './interfaces';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
}

Modal.setAppElement('#root');

interface CreateUpdateUserProps {
    refresh: () => any,
    client?: Client
}

const CreateUpdate: FunctionComponent<CreateUpdateUserProps> = ({refresh, client}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [clientData, setClientData] = useState<Client>(client || {name: ""});

    const saveClient = (e: FormEvent) => {
        e.preventDefault();
        const api = `${API}/api/clients`;
        const clientFormData = new FormData(document.getElementById("clientForm") as HTMLFormElement);

        Axios.post(api, clientFormData, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                'content-type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Cliente añadido exitosamente.");
            }
            setIsOpen(false);
            setClientData({name: ""});
            refresh();
        }).catch (err => {
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    const updateClient = (e: FormEvent) => {
        e.preventDefault();
        const api = `${API}/api/clients/${clientData.id}?_method=PUT`;
        const clientFormData = new FormData(document.getElementById("clientForm") as HTMLFormElement);
        Axios.post(api, clientFormData, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Cliente actualizado exitosamente.");
            }
            setIsOpen(false);
            setClientData(clientData);
            refresh();
        }).catch (err => {
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div style={{display: clientData.id ? "inline-block" : "block"}}>
            {clientData.id ? 
            <button className="btn btn-primary" onClick={() => setIsOpen(true)}>Editar</button>
            :
            <button className="btn btn-primary position-fixed" style={{bottom: 10, right: 10}} onClick={() => setIsOpen(true)}>Crear nuevo cliente</button>
            }
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={() => setIsOpen(false)}>
                <h5>{clientData.id ? `Editar a ${clientData.name}` : "Crear nuevo cliente"}</h5>
                <form onSubmit={clientData.id ? updateClient : saveClient} id="clientForm" encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input required name="name" value={clientData.name} onChange={e => setClientData({...clientData, name: e.target.value})} type="text" className="form-control" placeholder="Nombre"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="logo">Logotipo</label>
                        <input accept="image/png, image/jpeg" required={clientData.id ? false : true} type="file" className="form-control" name="logo" placeholder="Logotipo"/>
                    </div>
                    <button className="btn btn-primary" type="submit">Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default CreateUpdate;