import React, { useState, FormEvent, FunctionComponent, useEffect } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import { API } from '../../utils/endpoints';
import { toast } from 'react-toastify';
import { Product } from './interfaces';
import { Category } from '../Categories/interfaces';
import { getBoolean, editorStateToHtml, getEditorState } from '../../utils/functions';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxWidth: '100%',
        maxHeight: '100%',
    }
}

Modal.setAppElement('#root');

interface CreateUpdateUserProps {
    refresh: (callback?: () => any) => any,
    product?: Product,
    categories: Array<Category>
}

const CreateUpdate: FunctionComponent<CreateUpdateUserProps> = ({refresh, product, categories}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [productData, setProductData] = useState<Product>(product || {name: "", description: "", is_service: false, category_id: 0});
    const [isSaving, setIsSaving] = useState(false);
    const [editor, setEditor] = useState(getEditorState(productData.description || ""));

    const saveProduct = (e: FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        let validationMessage = "";
        if (!productData.category_id) validationMessage += "- Seleccione una categoría.\n";
        if (!productData.name) validationMessage += "- Digite un nombre.\n"
        if (!productData.description) validationMessage += "- Coloque una descripción.\n"
        if (validationMessage){
            toast.error(validationMessage);
            setIsSaving(false);
            return;
        }
        const api = `${API}/api/products`;
        Axios.post(api, JSON.stringify(productData), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Producto/servicio añadido exitosamente.");
            }
            setIsOpen(false);
            setProductData({name: "", is_service: false, category_id: 0});
            refresh(() => setIsSaving(false));
        }).catch (err => {
            setIsSaving(false);
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    const updateProduct = (e: FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        let validationMessage = "";
        if (!productData.category_id) validationMessage += "- Seleccione una categoría.\n";
        if (!productData.name) validationMessage += "- Digite un nombre.\n"
        if (!productData.description) validationMessage += "- Coloque una descripción.\n"
        if (validationMessage){
            toast.error(validationMessage);
            setIsSaving(false);
            return;
        }
        const api = `${API}/api/products/${productData.id}`;
        Axios.put(api, JSON.stringify(productData), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Producto actualizado exitosamente.");
            }
            setIsOpen(false);
            // setProductData(productData);
            refresh(() => setIsSaving(false));
        }).catch (err => {
            setIsSaving(false);
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    useEffect(() => {
        setProductData({...productData, description: editorStateToHtml(editor)})
    }, [editor]);


    return (
        <div style={{display: productData.id ? "inline-block" : "block"}}>
            {productData.id ? 
            <button className="btn btn-primary" onClick={() => setIsOpen(true)}>Editar</button>
            :
            <button className="btn btn-primary position-fixed" style={{bottom: 10, right: 10}} onClick={() => setIsOpen(true)}>Crear nuevo producto</button>
            }
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={() => setIsOpen(false)}>
                <h5>{productData.id ? "Editar producto": "Crear nuevo producto"}</h5>
                <form onSubmit={productData.id ? updateProduct : saveProduct} id="clientForm" encType="multipart/form-data">
                    <div className="custom-control custom-switch">
                        <input checked={getBoolean(productData.is_service)} onChange={e => setProductData({...productData, is_service: e.target.checked})} type="checkbox" className="custom-control-input" id="isService" name="isService" />
                        <label className="custom-control-label" htmlFor="isService">Es servicio</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input required name="name" value={productData.name} onChange={e => setProductData({...productData, name: e.target.value})} type="text" className="form-control" placeholder="Nombre"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="idCategoria">Categoría</label>
                        <select required value={productData.category_id} onChange={e => setProductData({...productData, category_id: parseInt(e.target.value)})} className="form-control" name="idCategoria">
                            <option value={0}>.:: Seleccione una categoría ::.</option>
                            {categories.filter(category => getBoolean(category.is_service) === productData.is_service).map(category => (
                                <option value={category.id} key={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Descripción</label>
                        <div style={{border: "1px solid #dbdbdb", minHeight: 300, padding: 10, borderRadius: 5}}>
                            <Editor 
                            editorState={editor}
                            onEditorStateChange={editorState => setEditor(editorState)}
                            />
                        </div>
                        {/* <textarea required value={productData.description} onChange={e => setProductData({...productData, description: e.target.value})} placeholder="Descripción" className="form-control" name="description" cols={30} rows={5}></textarea> */}
                    </div>
                    {productData.id ? 
                    <div className="form-group">
                        <label htmlFor="order">Orden</label>
                        <input type="number" name="order" placeholder="Orden" required value={productData.order} onChange={e => setProductData({...productData, order: parseInt(e.target.value)})} className="form-control"/>
                    </div>
                    : null}
                    <button disabled={isSaving} className="btn btn-primary" type="submit">Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default CreateUpdate;