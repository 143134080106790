import React, { useState, useEffect } from 'react';
import PanelLayout from '../../components/PanelLayout';
import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';
import { ContactMessage } from './interfaces';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import moment from 'moment';

const Comments = () => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState<Array<ContactMessage>>([]);
    const [filters, setFilters] = useState({
        fechaInicio: moment().subtract(15, "day").format("YYYY-MM-DD"),
        fechaFin: moment().format("YYYY-MM-DD")
    });

    const fechasSonFiltrables = (): boolean => {
        if (filters.fechaInicio && filters.fechaFin){
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        const getComments = () => {
            const api = `${API}/api/contactMessages`;
            Axios.get(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                let comments: Array<ContactMessage> = [];
                if (res.status === 200){
                    comments = res.data;
                }
                setLoading(false);
                setComments(comments);
            })
        }

        getComments();
    }, []);

    if (loading) return (
        <PanelLayout>
            <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Cargando...</span>
                </div>
            </div>
        </PanelLayout>
    )
    return (
        <PanelLayout>
            <section className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Comentarios</h2>
                        <p>
                            Revise los comentarios que han hecho sus clientes en su sitio web.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="fechaInicio">Entre fecha</label>
                            <input value={filters.fechaInicio} onChange={e => setFilters({...filters, fechaInicio: e.target.value})} type="date" placeholder="Entre fecha" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="fechaInicio">Y fecha</label>
                            <input value={filters.fechaFin} onChange={e => setFilters({...filters, fechaFin: e.target.value})} type="date" placeholder="Y fecha" className="form-control" />
                        </div>
                    </div>
                    <div className="col-12">
                        {comments.filter(comment => fechasSonFiltrables() ? moment(comment.created_at).isBetween(filters.fechaInicio, filters.fechaFin, "day", "[]") : true).map(comment => (
                            <div className="row border-top border-bottom p-3" key={comment.id}>
                                <div className="col-1">
                                    <Icon size={1.5} path={mdiAccountCircle} />
                                </div>
                                <div className="col-11 d-flex justify-content-center flex-column">
                                    <span className="font-weight-bold">{comment.person_name}</span>
                                    <span className="small" style={{color: "#6C757D"}}>
                                        <a href={`tel:${comment.phone}`}>{comment.phone}</a> - <a href={`mailto:${comment.email}`}>{comment.email}</a> <br/>
                                        Interesado en {comment.product.name}
                                    </span>
                                </div>
                                <div className="col-12 small mt-2">
                                    {moment(comment.created_at).format("DD/MM/YYYY")} - {comment.message}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </PanelLayout>
    );
};

export default Comments;