import React, { useState, useEffect } from 'react';
import PanelLayout from '../../components/PanelLayout';
import { Product } from './interfaces';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import moment from 'moment';
import CreateUpdate from './CreateUpdate';
import { toast } from 'react-toastify';
import Images from './Images';
import { Category } from '../Categories/interfaces';
import { getBoolean } from '../../utils/functions';

const Products = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<Array<Product>>([]);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [filters, setFilters] = useState({
        showProducts: true,
        showServices: true
    });

    const getProducts = () => {
        setLoading(true);
        const api = `${API}/api/products`;
        Axios.get(api, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            let products: Array<Product> = [];
            if (res.status === 200){
                products = res.data;
            }
            setProducts(products);
            setLoading(false);
        })
    }

    const deleteProduct = (id: number) => {
        const userConfirms = window.confirm("¿Realmente desea eliminar el recurso seleccionado?\nEsta acción no se puede deshacer.");
        if (userConfirms){
            const api = `${API}/api/products/${id}`;
            Axios.delete(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                if (res.status === 200){
                    toast.info("Producto/servicio eliminado correctamente");
                    getProducts();
                }
            })
        }
    }

    useEffect(() => {
        const getCategories = () => {
            const api = `${API}/api/categories`;
            Axios.get(api).then(res => {
                let categories: Array<Category> = [];
                if (res.status === 200){
                    categories = res.data;
                }
                setCategories(categories);
            })
        }
        
        getCategories();
        getProducts();
    }, []);

    if (loading){
        return (
            <PanelLayout>
                <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Cargando...</span>
                    </div>
                </div>
            </PanelLayout>
        )
    }
    return (
        <PanelLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h2>Productos y servicios</h2>
                        <p>
                            Gestione sus productos y servicios. Recuerde que estos registros deberán tener, por lo menos, una imagen para ser visibles en su sitio web.
                            Mostrando:
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" onChange={e => setFilters({...filters, showProducts: e.target.checked})} checked={filters.showProducts} id="showProductsCheckbox"/>
                            <label className="form-check-label" htmlFor="showProductsCheckbox">
                                Mostrar productos
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" onChange={e => setFilters({...filters, showServices: e.target.checked})} checked={filters.showServices} id="showServicesCheckbox"/>
                            <label className="form-check-label" htmlFor="showServicesCheckbox">
                                Mostrar servicios
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Tipo</th>
                                        <th>Categoría</th>
                                        <th>Fecha de creación</th>
                                        <th>Fecha de última actualización</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products
                                    .filter(product => filters.showProducts ? true : product.is_service)
                                    .filter(product => filters.showServices ? true : !product.is_service)
                                    .map(product => (
                                        <tr key={product.id}>
                                            <td>{product.order}</td>
                                            <td>
                                                {product.name} <br/>
                                                {!product.images?.length ? <span style={{color: "#ba000d", fontWeight: "bold"}}>Este {getBoolean(product.is_service) ? "servicio" : "producto"} no contiene imágenes y no se visualizará en su sitio web.</span> : null}
                                            </td>
                                            <td>
                                                <div dangerouslySetInnerHTML={{__html: product.description || ""}}></div>
                                            </td>
                                            <td>{getBoolean(product.is_service) ? "Servicio" : "Producto"}</td>
                                            <td>{product.category ? product.category.name : "Error al identificar categoría"}</td>
                                            <td>{moment(product.created_at).format("DD/MM/YYYY hh:mm a")}</td>
                                            <td>{moment(product.updated_at).format("DD/MM/YYYY hh:mm a")}</td>
                                            <td>
                                                <Images product={product} refresh={getProducts} />
                                                <CreateUpdate categories={categories} refresh={getProducts} product={product} />
                                                <button onClick={() => deleteProduct(product.id || 0)} className="btn btn-danger m-1 ml-0">Eliminar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <CreateUpdate categories={categories} refresh={getProducts} />
        </PanelLayout>
    );
};

export default Products;