import React, { useState, FormEvent, FunctionComponent } from 'react';
import Modal from 'react-modal';
import Axios from 'axios';
import { API } from '../../utils/endpoints';
import { toast } from 'react-toastify';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
}

Modal.setAppElement('#root')

interface CreateUserProps {
    refresh: () => any
}

const CreateUser: FunctionComponent<CreateUserProps> = ({ refresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState({name: "", email: "", password: ""});

    const saveUser = (e: FormEvent) => {
        e.preventDefault();
        const api = `${API}/api/users`;
        Axios.post(api, userData, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Usuario añadido exitosamente.");
            }
            setIsOpen(false);
            setUserData({name: "", email: "", password: ""});
            refresh();
        }).catch (err => {
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div>
            <button className="btn btn-primary position-fixed" style={{bottom: 10, right: 10}} onClick={() => setIsOpen(true)}>Crear nuevo usuario</button>
            <Modal isOpen={isOpen} style={modalStyles} onRequestClose={() => setIsOpen(false)}>
                <h5>Crear nuevo usuario</h5>
                <form onSubmit={saveUser} id="addUserForm">
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input required value={userData.name} onChange={e => setUserData({...userData, name: e.target.value})} type="text" className="form-control" placeholder="Nombre"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Correo electrónico</label>
                        <input required value={userData.email} onChange={e => setUserData({...userData, email: e.target.value})} type="email" className="form-control" name="email" placeholder="Correo electrónico"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input required value={userData.password} onChange={e => setUserData({...userData, password: e.target.value})} type="password" className="form-control" name="password" placeholder="Contraseña"/>
                    </div>
                    <button className="btn btn-primary" type="submit">Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default CreateUser;