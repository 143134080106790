import React, { FunctionComponent } from 'react';
import Icon from '@mdi/react'

interface CardProps {
    title?: string,
    subtitle?: string | React.ReactNode,
    buttons?: Array<{onClick: () => any, text: string, variant?: "primary" | "secondary" | "danger"}>,
    icon?: string,
    imageUrl?: string
}

const Card: FunctionComponent<CardProps> = ({children, title, subtitle, buttons = [], icon, imageUrl}) => {
    return (
        <div className="card d-inline-block w-100 mt-3">
            {imageUrl ? <img src={imageUrl} className="card-img-top" alt={title} /> : null}
            <div className="card-body">
                {icon ? <Icon path={icon} size={3} /> : null}
                {title ? <h5 className="card-title">{title}</h5> : null}
                {subtitle ? <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6> : null}
                {children}
                {buttons ? 
                    <div className="display-block mt-2">
                        {buttons.map((button, index) => (
                            <button className={`btn btn-${button.variant || "primary"}`} onClick={button.onClick} key={index}>{button.text}</button>
                        ))}
                    </div>
                : null}
            </div>
        </div>
    );
};

export default Card;