import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Users from './pages/Users';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Categories from './pages/Categories';
import ProjectCategories from './pages/Categories/ProjectCategories';
import ProductCategories from './pages/Categories/ProductCategories';
import ServiceCategories from './pages/Categories/ServiceCategories';
import Clients from './pages/Clients';
import Products from './pages/Products';
import Projects from './pages/Projects';
import Settings from './pages/Settings';
import Comments from './pages/Comments';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/home" exact component={Home} />
        <Route path="/users" exact component={Users} />
        <Route path="/categories" exact component={Categories} />
        <Route path="/clients" exact component={Clients} />
        <Route path="/products" exact component={Products} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/categories/projects" exact component={ProjectCategories} />
        <Route path="/categories/products" exact component={ProductCategories} />
        <Route path="/categories/services" exact component={ServiceCategories} />
        <Route path="/comments" exact component={Comments} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer />
    </Router>
  );
}

export default App;
