import React, { useState, useEffect } from 'react';
import PanelLayout from '../../../components/PanelLayout';
import { ProjectCategory } from './interfaces';
import { API } from '../../../utils/endpoints';
import Axios from 'axios';
import CreateCategory from './CreateCategory';
import { toast } from 'react-toastify';
import EditCategory from '../EditCategory';

const ProjectCategories = () => {
    const [categories, setCategories] = useState<Array<ProjectCategory>>([]);

    const getCategories = () => {
        const api = `${API}/api/projectCategories`;
        Axios.get(api).then(res => {
            let categories: Array<ProjectCategory> = [];
            if (res.status === 200){
                categories = res.data;
            }
            setCategories(categories);
        })
    }

    const deleteCategory = (id: number) => {
        const userConfirms = window.confirm("¿Realmente desea eliminar esta categoría?\nEsta acción no se puede deshacer.");
        if (userConfirms){
            const api = `${API}/api/projectCategories/${id}`;
            Axios.delete(api, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
                }
            }).then(res => {
                if (res.status === 200){
                    toast.info("Categoría eliminada correctamente");
                    getCategories();
                }
            })
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Categorías de proyectos</h2>
                    </div>
                    <div className="col-12 table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map(category => (
                                <tr key={category.id}>
                                    <td>{category.name}</td>
                                    <td>
                                        <EditCategory refresh={getCategories} category={category} isProject />
                                        <button className="btn btn-danger ml-2" onClick={() => deleteCategory(category.id || 0)}>Eliminar</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <CreateCategory refresh={getCategories} />
        </PanelLayout>
    );
};

export default ProjectCategories;