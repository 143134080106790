import React, { useState, FormEvent } from 'react';
import Modal from 'react-modal';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { toast } from 'react-toastify';

const modalStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
}

Modal.setAppElement('#root');

const ChangePassword = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({password: "", repeatPassword: ""});
    const [isSaving, setIsSaving] = useState(false);

    const changePassword = (e: FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        let validationMessage = "";
        if (formData.password !== formData.repeatPassword) validationMessage += "- Las contraseñas no coinciden.\n";
        if (validationMessage){
            toast.error(validationMessage);
            setIsSaving(false);
            return;
        }
        const api = `${API}/api/user/password`;
        Axios.put(api, JSON.stringify({password: formData.password}), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Contraseña actualizada correctamente.");
            }
            setIsOpen(false);
        }).catch (err => {
            setIsSaving(false);
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div>
            <button className="btn btn-primary btn-block" onClick={() => setIsOpen(true)}>Cambiar mi contraseña</button>
            <Modal style={modalStyles} isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
                <h5>Cambiar mi contraseña</h5>
                <form onSubmit={changePassword}>
                    <div className="form-group">
                        <label htmlFor="password">Nueva contraseña</label>
                        <input required value={formData.password} onChange={e => setFormData({...formData, password: e.target.value})} type="password" className="form-control" placeholder="Contraseña"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Repetir contraseña</label>
                        <input required value={formData.repeatPassword} onChange={e => setFormData({...formData, repeatPassword: e.target.value})} type="password" className="form-control" placeholder="Contraseña"/>
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={isSaving}>Guardar</button>
                    <button className="btn btn-secondary ml-2" onClick={() => setIsOpen(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default ChangePassword;