import React, { useState, useEffect, FunctionComponent, useCallback } from 'react';
import PanelLayout from './../../components/PanelLayout';
import Card from './../../components/Card';
import { mdiAccount } from '@mdi/js'; 
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { User } from './interfaces';
import moment from 'moment';
import 'react-tiny-fab/dist/styles.css';
import CreateUser from './CreateUser';

const Users: FunctionComponent = () => {
    const [usuarios, setUsuarios] = useState<Array<User>>([]);
    const [loading, setLoading] = useState(true);

    const getUsers = useCallback(() => {
        const api = `${API}/api/users`;
        Axios.get(api, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            let usuarios: Array<User> = [];
            if (res.status === 200){
                usuarios = res.data;
            }
            setUsuarios(usuarios);
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    if (loading){
        return (
            <PanelLayout>
                <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Cargando...</span>
                    </div>
                </div>
            </PanelLayout>
        )
    }
    return (
        <PanelLayout>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Usuarios</h2>
                    </div>
                    {usuarios.map(usuario => (
                    <div className="col-12 col-md-6 col-lg-4" key={usuario.id}>
                        <Card title={usuario.name} subtitle={<a href={`mailto:${usuario.email}`}>{usuario.email}</a>} icon={mdiAccount}>
                            Usuario creado el {moment(usuario.created_at).utc().format("DD/MM/YYYY")}
                        </Card>
                    </div>
                    ))}
                </div>
            </div>
            <CreateUser refresh={getUsers} />
        </PanelLayout>
    );
};

export default Users;