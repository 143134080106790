import React, { FunctionComponent } from 'react';
import styles from './PanelLayout.module.css';
import { Link, useHistory } from 'react-router-dom';
import { links } from './links';
import Icon from '@mdi/react'
import { mdiLogout } from '@mdi/js'; 
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { mdiMenu } from '@mdi/js'; 

interface PanelLayout {

}

const PanelLayout: FunctionComponent<PanelLayout> = ({ children }) => {
    const history = useHistory();

    const logout = () => {
        const api = `${API}/api/logout`;
        Axios.post(api, {}, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`
            }
        }).then(res => {
            if (res.status === 200){
                sessionStorage.clear();
                history.push('/');
            }
        })
    }

    const toggleNavbar = () => {
        const navbarItems = document.getElementById("navbarItems");
        if (navbarItems) {
            if (!navbarItems.style.display) {
                navbarItems.style.display = "block";
            } else {
                navbarItems.style.display = "";
            }
        }
    }

    return (
        <div>
            <nav className={styles.sidebar}>
                <span className={styles.brand}>PANEL DE CONTROL</span>
                <div className={styles.dropdownButton} style={{justifyContent: "flex-end", padding: 5}}>
                    <button onClick={toggleNavbar} className={`btn btn-primary`}>
                        <Icon size={1} path={mdiMenu} />
                    </button>
                </div>
                <div className={styles.items} id="navbarItems">
                    {links.map((link, index) => (
                        <div className={styles.itemContainer} key={index}>
                            <Icon className={styles.icon} path={link.icon} size={1} color="white" />
                            <Link style={{display: "inline-block"}} to={link.url}>{link.name}</Link>
                            {link.divider ? 
                            <hr className={styles.separator}/>
                            : null }
                        </div>
                    ))}
                    <div className={styles.itemContainer}>
                        <Icon className={styles.icon} path={mdiLogout} size={1} color="white" />
                        <button onClick={logout} className={`btn text-white`} style={{display: "inline-block", borderRadius: 0}}>Cerrar sesión</button>
                    </div>
                </div>
            </nav>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default PanelLayout;