import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { API } from '../../utils/endpoints';
import Axios from 'axios';
import { Constant } from './interfaces';
import { getBoolean } from '../../utils/functions';
import { toast } from 'react-toastify';

const FlatDesign = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [flatEnabledData, setFlatEnabledData] = useState({
        description: "",
        id: 0,
        value: false,
        name: "flat",
    });

    useEffect(() => {
        const getConstants = () => {
            setLoading(true);
            const api = `${API}/api/constants`;
            Axios.get(api).then(res => {
                console.log(res);
                let constants: Array<Constant> = [];
                if (res.status === 200){
                    constants = res.data;
                }
                const enabledData = constants.filter(constant => constant.name === "flat")[0];
                setFlatEnabledData({
                    value: getBoolean(enabledData.value),
                    id: enabledData.id,
                    description: enabledData.description,
                    name: enabledData.name
                });
                setLoading(false);
            })
        }
        
        getConstants();
    }, []);

    const toggleFlat = () => {
        const api = `${API}/api/constants/${flatEnabledData.id}`;
        Axios.put(api, JSON.stringify({...flatEnabledData, value: !flatEnabledData.value ? "true" : "false"}), {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("jwt")}`,
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.status === 200){
                toast.success("Diseño flat habilitado/deshabilitado exitosamente.");
            }
        }).catch (err => {
            toast.error(`Algo ha salido mal: ${err}`)
        })
    }

    return (
        <div>
            <Button block className="mt-3" onClick={() => setOpen(true)}>Activar/Desactivar diseño Flat</Button>

            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Activar/Desactivar diseño flat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? 
                    <div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </div>
                    : 
                    <div>
                        <p>Habilite o deshabilite con el checkbox a continuación el diseño flat en su sitio web.</p>
                        <Form.Check 
                        checked={flatEnabledData.value} 
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFlatEnabledData({...flatEnabledData, value: e.target.checked}); 
                            setTimeout(toggleFlat, 500);
                        }} 
                        type="checkbox" 
                        label="Diseño flat" 
                        />
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpen(false)}>Aceptar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FlatDesign;